.App {
  font-family: 'Courier New', Courier, monospace;
}

.Home {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 15vh;
  /* width: 50vmin; */
  /* display: flex; */
  /* margin: auto; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  background-color: black;
}

.iphone {
  pointer-events: none;
  height: 550px;
  min-width: 300px;
}

.app-store-link {
  pointer-events: none;
}

.store-badge-app-store {
  margin: 10px;
}

.store-badge-google-play {
  margin: 10px;
}

.blurb-box {
  /* margin: auto; */
  vertical-align: middle;
  line-height: 75vh;
}
.blurb {
  display: inline-block;
  vertical-align: middle;
  line-height: 150%;
}
/* height: 75vh;
display: flex;
flex-direction: column;
margin: auto;
justify-content: center;
align-items: center;
max-width: 450px;  */

.download-text {
  padding: 20px;
}

.spacer {
  margin-top: 3%;
}

.footer-spacer {
  width: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.phone-only {
  display: none;
}

.desktop-only {
  display: block;
}

@media (max-width: 768px) {
  .App-logo {
    height: 10vh;
    width: 100vw;
    margin-top: 3%;
  }

  .blurb-box {
    line-height: 0;
    padding-bottom: 20px;
  }

  .blurb {
    height: 10vh;
    margin: 12px;
  }

  .iphone {
    pointer-events: none;
    min-height: 550px;
    min-width: 300px;
  }

  .spacer {
    margin-top: 10%;
  }

  .phone-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}
